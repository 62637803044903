/**
 * Receives an object with multiple financial attributes
 * @param {object} simulationParams object that contains all the financial info to be processed
 * @returns void
 * @author Lorena Carla & Rafael Almeida
 *Função construída com base nesse link https://docs.google.com/spreadsheets/d/16UB1nA3q0cIVjPAiixbcfsyEH6jVGDoBgBr72PM_JcA/edit#gid=570674978
 *
 */
/* eslint-disable no-restricted-properties */
import { alternativeFormater } from 'utils/formatNumber'

const SimulateValues = simulationParams => {
  const {
    priceValue,
    entryValue,
    // monthlyInterestValue,
    installmentsValue,
    setterFirstInstallment,
    setterCET,
  } = simulationParams
  if (!entryValue) {
    setterFirstInstallment('first_parcel_amount', null)
    setterCET('cet_percent', null)
    return
  }

  const principal = priceValue - entryValue
  const monthlyInterestValueEmgea = 0.6666666667
  const monthlyInterestRate = monthlyInterestValueEmgea / 100
  const numerator = principal * monthlyInterestRate
  const denominator = 1 - Math.pow(1 + monthlyInterestRate, -installmentsValue)
  const payment = numerator / denominator

  setterFirstInstallment(
    'first_parcel_amount',
    alternativeFormater(payment.toFixed(2))
  )

  // Calcular CET
  const annualInterest = monthlyInterestRate * 12
  const monthlyValue = Math.pow(1 + annualInterest, 1 / 12) - 1
  const installments = parseFloat(installmentsValue)
  const PMT =
    (principal * monthlyValue) / (1 - Math.pow(1 + monthlyValue, -installments))
  const totalPayed = PMT * installments
  const totalInterest = totalPayed - principal
  const CET = Math.pow(1 + totalInterest / principal, 12 / installments) - 1
  const percentCET = CET * 100
  setterCET('cet_percent', alternativeFormater(percentCET.toFixed(2)))
}

export default SimulateValues
